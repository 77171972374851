import React from 'react';
import { TelegramIcon } from '../icons/TelegramIcon';
import { TwitterIcon } from '../icons/TwitterIcon';

export const Button = ({ condition }) => {
  return (
    <a href={condition === "telegram" ? 'https://t.me/quadsol' : 'https://twitter.com/QUAD_COIN'} target='blank' className='bg-customRed p-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl w-12 h-12 md:w-auto md:h-auto flex justify-center items-center'>
      {
        condition === 'telegram' ? 
        <>
          <div className='md:block hidden'>
            <TelegramIcon />
          </div>
          <div className='block md:hidden'>
            <TelegramIcon width={24} height={20}/>
          </div>
        </>
          : 
          <>
          <div className='md:block hidden'>
            <TwitterIcon />
          </div>
          <div className='block md:hidden'>
            <TwitterIcon width={24} height={24}/>
          </div>
        </>
      }
    </a>
  );
};
