export const viewPortVariants = {
    hidden: {
        y: 200,
        opacity: 0,
        transition: {
            duration: 0.8,
            ease: "linear"
        }
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: "linear"
        }
    }
};