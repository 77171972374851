import React from 'react'

export const NextIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9861 8.0249L27.2549 14.5874C27.6299 14.9499 27.8174 15.4437 27.8174 15.9874C27.8174 16.5312 27.6299 17.0187 27.2549 17.3874L20.9861 23.9749C20.2424 24.7562 19.0299 24.7562 18.2861 23.9749C17.5424 23.1937 17.5424 21.9312 18.2861 21.1499L21.2986 17.9999H7.73613C6.67363 17.9999 5.81738 17.1062 5.81738 15.9999C5.81738 14.8937 6.67363 13.9999 7.72988 13.9999H21.2924L18.2799 10.8499C17.5361 10.0687 17.5361 8.80615 18.2799 8.0249C19.0299 7.24365 20.2361 7.24365 20.9861 8.0249Z" fill="white"/>
    </svg>

  )
}
