import React from 'react'

export const CustomDiv = ({children, bgColor}) => {
    const dynamicStyle = {
        backgroundColor: bgColor,
    }

    return (
        <div className='bg-white bg-opacity-10 md:py-6 md:px-8 p-6 md:text-[30px] text-[20px] rounded-tl-3xl rounded-br-3xl flex gap-4 items-center text-customGray'>
            <div>
                <div className='w-8 h-8 rounded-full' style={dynamicStyle}></div>
            </div>
            <h1>
                {children}
            </h1>
        </div>
    )
}
