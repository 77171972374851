import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import image1 from '../assets/image1.png'
import image2 from '../assets/image2.png'
import image3 from '../assets/image3.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../index.css';

// import required modules
import { Navigation, Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import stats from "../assets/stats.jpeg"
import meme2 from "../assets/slider/meme2.jpeg"
import meme3 from "../assets/slider/meme3.jpeg"

export default function Slider() {
    const [centeredSlide, setCenteredSlide] = useState(0);

    const handleSlideChange = (swiper) => {
      setCenteredSlide(swiper.realIndex);
    };

    return (
      <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          coverflow={{
              rotate: 0,
              stretch: 150,
              depth: 200,
              modifier: 1,
              slideShadows: false,
              scaleX: 0.2
          }}
          breakpoints={{
              0: {
                  slidesPerView: 1.5,
                  spaceBetween: -40
              },
              1024: {
                  slidesPerView: 2.8,
                  spaceBetween: -50
              }
          }}
        slidesPerView={3}
        initialSlide={1}
        navigation=
              {{
                prevEl: '#prevElement',
                nextEl: '#nextElement',
              }}
        modules={[Navigation,Autoplay,EffectCoverflow,Pagination]}
          autoplay={{
              delay: 5000,
              disableOnInteraction: false,
          }}
        speed={500}
        pagination={false}
        loop={true}
        onSlideChange={handleSlideChange}
        className="mySwiper"
      >
        <SwiperSlide className={`custom-slide ${centeredSlide !== 0 ? 'not-centered' : 'centered'}`}>
          <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567329515638945/QUAD_WORLD_TAKEOVER.jpg?ex=65a98be0&is=659716e0&hm=f31519748804a2d4537978deac2723b39562659137ef4b3971a2edc9e5569fee&'} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 1 ? 'not-centered' : 'centered' }`}>
          <img src={stats} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 2 ? 'not-centered' : 'centered' }`}>
          <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567330018951248/SQUAD_UP_ARMYv2.jpg?ex=65a98be0&is=659716e0&hm=20dfdee4da49ea17ca2aa7cea8a2ffea6c8e8952c08419e4e116d0c59bbcafdc&'} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 3 ? 'not-centered' : 'centered'}`}>
          <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567327808557118/PEAKY_QUAD_1.jpg?ex=65a98bdf&is=659716df&hm=2b598fd669127b360c3b7901fd47ae12d8f413304617451a54dccfee9b0a441c&'} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 4 ? 'not-centered' : 'centered'}`}>
            <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567327393316985/QUADFACE.jpg?ex=65a98bdf&is=659716df&hm=fb0b4833c4b5cdc8bbf2995c1ee7d785842af105387d7b6abc02a58739791a00&'} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 5 ? 'not-centered' : 'centered'}`}>
            <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567330400637099/Casa_del_Quad_1.jpg?ex=65a98be0&is=659716e0&hm=c494c270940b1b651b983aaffd8b5d0fb466d24c25208a6db2cd43c9c224766f&'} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 6 ? 'not-centered' : 'centered'}`}>
            <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567330803306586/QUAD_COIN_Z_1.jpg?ex=65a98be0&is=659716e0&hm=77ac0c0c67aea7c18af3aba840144566b42e5cdba22e9c104082252397f8697a&'} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 7 ? 'not-centered' : 'centered'}`}>
            <img src={'https://cdn.discordapp.com/attachments/868966998023999499/1192567331365322783/QUAD_COIN_SOLANA_V_1.jpg?ex=65a98be0&is=659716e0&hm=9aa9864354d1202e547d4999ca52699f096fdbfab92463f593b27b14048f2688&'} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 8 ? 'not-centered' : 'centered'}`}>
            <img src={meme2} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide className={`custom-slide ${centeredSlide !== 9 ? 'not-centered' : 'centered'}`}>
            <img src={meme3} alt="Slide 3" />
        </SwiperSlide>
      </Swiper>
    );
}
