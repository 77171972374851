import React from 'react'

export const TelegramIcon = ({width, height}) => {
  return (
    <svg width={width ? width : `41`} height={height ? height : `35`} viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.1993 1.23338L33.9512 32.7471C33.9512 32.7471 33.0762 34.9346 30.67 33.8818L16.2461 22.8213L10.9961 20.292L2.1641 17.3252C2.1641 17.3252 0.81058 16.8467 0.673862 15.7939C0.537143 14.7412 2.20511 14.1806 2.20511 14.1806L37.3145 0.413069C37.3145 0.399397 40.1993 -0.872087 40.1993 1.23338Z" fill="#F1E8D2"/>
        <path d="M15.125 32.3916C15.125 32.3916 14.7012 32.3506 14.1816 30.6963C13.6621 29.042 10.9961 20.292 10.9961 20.292L32.2012 6.8252C32.2012 6.8252 33.4316 6.08691 33.377 6.8252C33.377 6.8252 33.5957 6.96192 32.9395 7.56348C32.2832 8.17871 16.2871 22.5615 16.2871 22.5615" fill="#CAC0A7"/>
        <path d="M21.7695 27.0596L16.0684 32.2686C16.0684 32.2686 15.6172 32.6104 15.1387 32.3916L16.2324 22.7256" fill="#A09476"/>
    </svg>
  )
}
