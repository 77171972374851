import React from "react";
import sunLights from "./assets/SunLights.png"
import logo from "./assets/logo.png"
import { Button } from "./Components/Button";
import solanaMD from "./assets/solanaduckmd.svg"
import solanaSM from "./assets/solanaDucksm.png"
import cloud1 from "./assets/clouds/Vector.png"
import cloud3 from "./assets/clouds/Vector-2.png"
import lake from "./assets/lake.png"
import circle from "./assets/circle.png"
import bg from "./assets/bg.png"
import { motion } from "framer-motion"
import { CustomDiv } from "./Components/CustomDiv";
import Slider from "./Components/Slider"
import { PrevIcon } from "./icons/PrevIcon";
import { NextIcon } from "./icons/NextIcon";
import { TelegramIcon } from "./icons/TelegramIcon";
import { TwitterIcon } from "./icons/TwitterIcon";
import AnimateText from "./Components/AnimateText";
import {viewPortVariants} from "./animation/ViewPortVariants";
import {fade} from "./animation/Fade";

function App() {
  const divStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vw',
  };
  return (
    <div>
      <div className="relative flex flex-col justify-center overflow-hidden">
        <div className="relative">
          <img src={sunLights} className="absolute animate-pulse" alt={''}/>
          <img src={cloud3} className="absolute opacity-20 cloud-2 top-5 w-1/2 md:w-auto" alt={''}/>
          <img src={cloud1} className="absolute sunLight opacity-20 top-44 w-1/2 md:w-auto" alt={''}/>
          <img src={cloud3} className="absolute sunLight opacity-20 cloud-1 md:top-96 top-72 w-1/2 md:w-auto" alt={''}/>
          <motion.div
              initial="hidden"
              animate="visible"
              variants={fade}
              className="flex flex-col justify-center mt-5 relative">
            <img src={logo} className="place-self-center w-[194px] h-[194px] md:w-[340px] md:h-[340px]" alt={''}/>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-center gap-2">
              <Button condition={'telegram'}/>
              <Button condition={'twitter'}/>
            </div>
          </motion.div>
        </div>

        <div className="place-self-center flex flex-col items-center gap-2 md:gap-4 mt-24 text-white">
          <h3 className="md:text-[40px] text-2xl">Supply</h3>
          <div className="relative">
            <AnimateText/>
            <div className="absolute md:-top-7 md:-right-16 right-0 -top-14">
              <div className="bg-customRed p-3 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl md:text-2xl text-base md:rotate-[15deg] -rotate-[15deg]">
                $QUAD
              </div>
            </div>
          </div>
        </div>

        <motion.div
            viewport={{amount: 0, once: true}}
            initial="hidden"
            whileInView="visible"
            variants={viewPortVariants}>
          <div className="z-[999] relative">
            <img src={lake} alt="" className="w-screen z-10 mt-10"/>
            <motion.div
                viewport={{amount: 0.2, once: true}}
                initial="hidden"
                whileInView="visible"
                variants={fade} className="relative">
              <img src={solanaMD} alt="" className="absolute -bottom-80 left-1/2 transform -translate-x-[40%] md:block hidden"/>
              <img src={solanaSM} alt="" className="absolute md:-bottom-96 -bottom-[350px] left-1/2 transform md:-translate-x-1/2 -translate-x-[45%] md:w-auto md:h-auto w-[300px] h-[450px] block md:hidden"/>
            </motion.div>
          </div>
        <div className="md:pt-72 pt-44 pb-2 md:-mt-[150px] -mt-10 relative bg-customGreen-light w-screen flex flex-col items-center gap-9">
          <motion.h1
              viewport={{amount: 0.4, once: true}}
              initial="hidden"
              whileInView="visible"
              variants={fade} className="text-white md:text-5xl text-[32px] z-10">
            Token address Soon
          </motion.h1>
          <div className="text-white md:text-xl text-md text-center px-10">
            Crafted for excitement, Quad Coin brings easy, fun adventures with every click. Dive into the thrill today!
          </div>
          <button className=" bg-customRed px-7 py-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl md:text-2xl text-[20px] text-white -rotate-3 z-10">
            BUY $QUAD
          </button>
        </div>
      </motion.div>

        <div className="bg-image" style={divStyle}>
          <div className="z-10 text-white mt-14 flex flex-col gap-12 items-center">
            <img src={circle} alt="" className="md:w-[760px] md:h-[760px] w-[365px] h-[365px]"/>
            <h1 className="text-5xl md:block hidden">
              TOKENOMICS
            </h1>
            <div className="flex flex-col gap-6 items-start mx-[28px] md:mt-0 mt-[26px]">
              <CustomDiv bgColor={"#295506"}>80% liquidity pool</CustomDiv>
              <CustomDiv bgColor={"#F1E8D2"}>9% Ambassadors</CustomDiv>
              <CustomDiv bgColor={"#AF4F4D"}>7% team</CustomDiv>
              <CustomDiv bgColor={"#1F1E1F"}>4% Airdrop</CustomDiv>
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex justify-center text-white md:text-[40px] text-2xl md:pb-[120px] pb-[67px] items-center md:gap-24 gap-[34px]">
          <button id='prevElement' className='bg-customRed p-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl flex justify-center items-center'>
            <PrevIcon />
          </button>
          <h1>MEME WAR</h1>
          <button id='nextElement' className='bg-customRed p-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl flex justify-center items-center'>
            <NextIcon />
          </button>
          </div>
          <Slider />
        </div>

        <div className="my-[60px] flex md:flex-row flex-col gap-2 justify-center items-center relative">
          <div className="absolute h-px w-screen bg-customGreen-line md:bottom-1/2 top-10"></div>
          <a href="https://t.me/quadsol" target="blank" className='max-w-[228px] bg-customRed p-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl flex justify-center items-center gap-4 z-10'>
            <TelegramIcon />
            <h1 className="text-white">
              t.me/quadsol
            </h1>
          </a>
          <a href="https://twitter.com/QUAD_COIN" target="blank" className='max-w-[228px] bg-customRed p-4 md:border-[5px] border-[3px] border-border rounded-tl-3xl rounded-br-3xl flex justify-center items-center gap-4 z-10'>
            <TwitterIcon />
            <h1 className="text-white">
              #QUAD_COIN
            </h1>
          </a>
        </div>

      </div>
    </div>
  );
}

export default App;
